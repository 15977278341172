<div fxLayout="column" fxFlexFill>
  <mat-toolbar color="accent" class="mat-elevation-z6 fixed-header">
        <a>{{title}}</a>
        <span fxFlex></span>
  </mat-toolbar>
  <div class="area" >
  <div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
    <mat-card appearance="outlined" class="box">
      <mat-card-header>
        <mat-card-title>Anmeldung</mat-card-title>
      </mat-card-header>
      <form class="example-form" [formGroup]="userForm">
        <mat-card-content>
          <mat-form-field class="example-full-width">
            <input matInput placeholder="Benutzername/E-Mail" formControlName="username">
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <input matInput placeholder="Passwort"   [type]="show ? 'text' : 'password'" formControlName="password">
            <button mat-icon-button matSuffix (click)="   this.show = !this.show;"  [attr.aria-label]="'Hide password'" [attr.aria-pressed]="show">
              <mat-icon >{{show ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
          </mat-form-field>
 
          <mat-form-field class="example-full-width" *ngIf="environment.clientID == null">
            <mat-label>Mandant</mat-label>
            <mat-select formControlName="system" [disabled]="this.hasClientSetPerURL">
              <mat-option *ngFor="let c of systemConfigs" [value]="c">{{ c.name  | anonymize : c}}</mat-option>
            </mat-select>
          </mat-form-field> 
        </mat-card-content>

        <!-- <button mat-stroked-button color="accent" style="margin-bottom: 15px;" class="btn-block" type="submit" (click)="lostPW()">Passwort vergessen</button> -->

        <button mat-stroked-button color="primray" class="btn-block" type="submit" (click)="login()"
          [disabled]="!userForm.valid">Anmelden</button>
      </form>
    </mat-card>
  </div>



 
    <ul class="circles">
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
  </div>
  <!-- <app-footer></app-footer> -->
</div>