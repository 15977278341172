import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { User } from 'src/app/interfaces/user';
import { BusinessuserService } from 'src/app/services/businessuser.service';
import { OverlayService } from 'src/app/services/overlay.service';
/**
 * The Data to be passed into the Dialog
 */
export interface DialogData {
  /**
   * The Title of the Dialog
   */
  title: string;
  /**
   * The message in the Dialog
   */
  message: string;
  /**
   * The okText on the Button
   */
  okText: string;
  /**
   * The Text on the cancel Button
   */
  cancelText: string;
  /**
   * The user to be deactiaved
   * 
   */
  customer: User;
}
@Component({
  selector: 'app-deactivate-user',
  templateUrl: './deactivate-user.component.html',
  styleUrl: './deactivate-user.component.scss'
})
export class DeactivateUserComponent {
  /**
   * Open the Mailpage
   */
  sendMail:boolean = false;
  /**
   * Form
   */
  deactivateForm:UntypedFormGroup;
  /**
   * constructor
   * @param dialogRef 
   * @param data 
   */
  constructor(
    public dialogRef: MatDialogRef<DeactivateUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private businessuserService: BusinessuserService,
    private overlay:OverlayService,
    private fb:UntypedFormBuilder,
    private router:Router
  ) { 
    this.deactivateForm = this.fb.group({
      sendMail: [false]
    })
  }

  ngOnInit(): void {
  }
  /**
  * Close the Dialog and emit false
  */
  cancel(): void {
    this.dialogRef.close();
  }
  /**
   * Close the Dialog and emit true
   */
  continue() {
    this.businessuserService.deactivateUser(this.data.customer).subscribe(value => {
      if (value) {
        this.overlay.openSnackBar("Nutzer erfolgreich deaktiviert!");
        this.cancel();
        if(this.deactivateForm.getRawValue().sendMail == true){ 
          this.router.navigate(['caseworker','dashboard','contact', this.data.customer.publicId])
        }
      }
    })
  }
}
