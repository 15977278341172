import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from './environment.service';
import { map, switchMap } from 'rxjs/operators';
import { JwtHelperService } from "@auth0/angular-jwt";
import { OverlayService } from './overlay.service';
import { Location } from '@angular/common';
import config from './../../assets/config/psconfig.json';
import { AccessTokenData } from '../interfaces/access-token-data';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AccountResource } from '../interfaces/account-resource';
/**
 * Service to auth Users
 */
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  /**
   * The Currentuser as a BehaviorSubject
   */
  private currentUserSubject: BehaviorSubject<any>;
  /**
   * The Currentuser as a Observable
   */
  public currentUser: Observable<any>;
  /**
   * The Helper to decode the Token
   */
  private helper = new JwtHelperService();
  /**
   * The current Token decoded as a BehaviorSubject
   */
  public decodedToken$: BehaviorSubject<AccessTokenData> = new BehaviorSubject(null);
  /**
   * The current Config
   */
  private clientConfig = config;
  /**
   * The Role of the current User
   */
  public userRole = null;
  /**
   * constructor
   * Set the currentUserSubject and currentUser
   * Load the CurrentConfig
   * @param http 
   * @param environment 
   * @param overlayService 
   * @param location 
   * @param environmentService 
   * @param router 
   */
  constructor(
    private http: HttpClient,
    private environment: EnvironmentService,
    private overlayService: OverlayService,
    private location: Location,
    private router: Router
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
    this.loadClientConfig();
  }

  doPasswordLost(account: AccountResource): Observable<AccountResource> {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post<AccountResource>(`${url}/account/`, account))
    )
  }

  /**
   * Login the User via PW and name
   * @param username 
   * @param password 
   * @param config 
   * @returns 
   */
  login(username: string, password: string, config) {
    this.environment.setClientConfig(config);

    return this.http
      .post<any>(`${this.environment.getApiUrl()}/authorize`, {
        username,
        password
      })
      .pipe(
        map(user => {
          // login successful if there's a jwt token in the response
          if (user && user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            this.setCurrentUser(user, config);
          }
          return user;
        })
      );
  }
  /**
   * Return the Value form the currentUserSubject
   */
  public get currentUserValue() {
    return this.currentUserSubject.value;
  }

  public getcurrentUserObs() {
    return this.currentUserSubject;
  }
  /**
   * Set the Current user and Save to Storage
   * @param user 
   * @param config 
   */
  public setCurrentUser(user, config) {
    const decodedToken = this.helper.decodeToken(user.token);

    if (decodedToken.clientId == 666 && decodedToken.role == 'ROLE_ADMIN') {
      this.userRole = 'superadmin';
    } else if (decodedToken.role == 'ROLE_CASEWORKER') {
      this.userRole = 'sb';
    } else if (decodedToken.role == 'ROLE_ADMIN') {
      this.userRole = 'admin';
    } else if (decodedToken.role == 'ROLE_PARTNER') {
      this.userRole = 'partner';
    }

    this.decodedToken$.next(decodedToken)
    this.environment.setClientConfig(config);
    localStorage.setItem('currentUser', JSON.stringify({ token: user.token, }));
    let config2 = {
      "id": environment.clientID,
      "name": environment.appName,
      "backend": environment.apiURL,
      "property": 1
    }
    if (environment.clientID == null) {
      localStorage.setItem('config', JSON.stringify(config));
    } else {
      localStorage.setItem('config', JSON.stringify(config2));
    }
    this.currentUserSubject.next(user);
  }
  /**
   * logout and remove the config and user from the Storage
   */
  public logout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('config');
    this.userRole = null;
    this.currentUserSubject.next(null);
    window.location.reload();
    this.router.navigate(['login'])
  }
  /**
   * Check if the user is a Superadmin
   * @returns 
   */
  public isSuperAdmin() {
    return this.userRole == 'superadmin';
  }
  /**
   * Check if the user is a sb
   * @returns 
   */
  public isSB() {
    return this.userRole == 'sb';
  }
  /**
   * Check if the user is an admin
   * @returns 
   */
  public isAdmin() {
    return this.userRole == 'admin';
  }
  /**
   * Check if the user is a partner
   * @returns 
   */
  public isPartner() {
    return this.userRole == 'partner';
  }

  /**
   * Get the currentConfig from the localStorage
   */
  private loadClientConfig() {
    const data = JSON.parse(localStorage.getItem('currentUser'))
    if (data && data.token) {
      const decodedToken = this.helper.decodeToken(data.token);
      const config = JSON.parse(localStorage.getItem('config'))
      this.setCurrentUser(data, config);
      this.decodedToken$.next(decodedToken)
    }
  }
  /**
 * @deprecated
 * used in Old View
   */
  getClientSystemList() {
    const filtered = Object.keys(this.clientConfig).filter(id => id.indexOf('LIVE_') >= 0);
    filtered.push('DEMO_100');
    filtered.push('DEV_666');

    return filtered.map(key => this.clientConfig[key])
      .map(entry => {
        entry['DISPLAY_NAME'] = entry['DISPLAY_NAME'].replace(' (Live)', '');
        entry['DISPLAY_NAME'] = entry['DISPLAY_NAME'].replace(' (LIVE)', '');
        return entry
      })
      .sort((a, b) => {
        return a.DISPLAY_NAME.localeCompare(b.DISPLAY_NAME);
      });
  }
  /**
   * @deprecated
   * used in Old View
   * @param clientId 
   * @returns 
   */
  getClientConfigById(clientId) {
    const configs = this.getClientSystemList();
    return configs.filter(config => config.clientId == clientId)[0];
  }
  /**
   * @deprecated
   * used in Old View
   * @param newClientUrl 
   * @param clientId 
   * @returns 
   */
  getTokenForClient(newClientUrl, clientId) {
    return this.http.post(`${newClientUrl}/system-token`, { token: this.currentUserValue.token }).pipe(
      map(res => {
        const config = this.getClientConfigById(clientId);
        this.setCurrentUser({ token: res['token'] }, config);
      })
    )
  }


  /**
* @deprecated
* used in Old View
* @param token 
* @param clientId 
*/
  public validateTokenAndSetUser(token, clientId) {
    try {
      const decodedToken = this.helper.decodeToken(token);
      const expirationDate = this.helper.getTokenExpirationDate(token);
      const isExpired = this.helper.isTokenExpired(token);
      // console.log('decodedToken: ', decodedToken);
      // console.log('expirationDate: ', expirationDate);
      // console.log('isExpired: ', isExpired);
      const config = this.getClientConfigById(clientId);
      // console.log('config for user: ', config);

      if (!isExpired) {
        this.setCurrentUser({ token: token }, config);
      } else {
        this.overlayService.openSnackBar('Der Token ist abgelaufen.', 'error');
        this.location.replaceState('');
      }
    } catch (e) {
      this.overlayService.openSnackBar('Ungültiger Token.', 'error');
      this.location.replaceState('');
    }
  }
}
