<h3 mat-dialog-title>{{ data.title }}</h3>
<div mat-dialog-content [formGroup]="deactivateForm">
   {{ data.message}}
 
   <mat-checkbox class="example-margin" formControlName="sendMail">  Benachrichtigung senden</mat-checkbox>
</div>

<div mat-dialog-actions align="end">
    <button mat-button (click)="cancel()">{{data.cancelText}}</button>
    <button mat-button mat-raised-button color="primary" cdkFocusInitial (click)="continue()">{{ data.okText }}</button>
</div>