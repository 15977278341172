import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

import { DialogComponent } from '../components/shared/dialog/dialog.component';
import { EditVoucherCodesDialogComponent } from '../components/shared/dialog/edit-voucher-codes-dialog/edit-voucher-codes-dialog.component';
import { ShowVoucherCodesComponent } from '../components/shared/dialog/show-voucher-codes/show-voucher-codes.component'
import { ChangeEventStateComponent } from '../components/shared/bottomSheets/change-event-state/change-event-state.component'
import { ChangeBusinessEntityStateComponent } from '../components/shared/bottomSheets/change-business-entity-state/change-business-entity-state.component'
import { ChangeBusinessUserStateComponent } from '../components/shared/bottomSheets/change-business-user-state/change-business-user-state.component'
import { ChangeBusinessUserRoleComponent } from '../components/shared/bottomSheets/change-business-user-role/change-business-user-role.component'
import { AddHouseHoldmemberComponent } from '../components/shared/dialog/add-house-holdmember/add-house-holdmember.component'
import { ResetPWForUserComponent } from '../components/shared/dialog/reset-pwfor-user/reset-pwfor-user.component'
import { ShowQrCodeComponent } from '../components/shared/dialog/show-qr-code/show-qr-code.component'

import { Observable } from 'rxjs';
import { EventResource } from '../interfaces/event-resource';
import { DeleteDialogComponent } from '../components/shared/dialog/delete-dialog/delete-dialog.component';
import { ErrorDialogComponent } from '../components/shared/dialog/error-dialog/error-dialog.component';
import { BusinessEntityResource } from '../interfaces/business-entity-resource';
import { BusinessUserResource } from '../interfaces/business-user-resource';
import { AddCategoriesComponent } from '../components/shared/dialog/add-categories/add-categories.component';
import { ShowCardQrCodeComponent } from '../components/shared/dialog/show-card-qr-code/show-card-qr-code.component';
import { CardResource } from '../interfaces/card-resouce';
import { DeleteComponent } from '../components/shared/dialog/delete/delete.component';
import { AutoUpdateEventStatusComponent } from '../components/shared/dialog/auto-update-event-status/auto-update-event-status.component';
import { ReopenAccountComponent } from '../components/shared/dialog/reopen-account/reopen-account.component';
import { User } from '../interfaces/user';
import { LostPWComponent } from '../components/shared/dialog/lost-pw/lost-pw.component';
import { DelteUserComponent } from '../components/shared/dialog/delte-user/delte-user.component';
import { SearchCustomerComponent } from '../components/shared/dialog/search-customer/search-customer.component';
import { ContactChannelResource } from '../interfaces/contactchannel';
import { EditMailContactComponent } from '../components/shared/dialog/edit-mail-contact/edit-mail-contact.component';
import { AutoUpdateEventInfoStatusComponent } from '../components/shared/dialog/auto-update-event-status-info/auto-update-event-status.component';
import { DeactivateUserComponent } from '../components/shared/dialog/deactivate-user/deactivate-user.component';
/**
 * The Servie to show the Modals / Toasts
 */
@Injectable({
  providedIn: 'root'
})
export class OverlayService {
  /**
   * 
   * @param _snackBar 
   * @param dialog 
   * @param _bottomSheet 
   */
  constructor(
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private _bottomSheet: MatBottomSheet) { }

  /**
   * Open a Snackbar 
   * @param msg 
   * @param cssClass 
   * @param duration 
   */
  openSnackBar(msg, cssClass = 'default', duration = 5000) {
    this._snackBar.open(msg, null, { duration: duration, horizontalPosition: 'center', verticalPosition: 'bottom', panelClass: cssClass });
  }

  /**
   * Open the default Dialog 
   * @param title 
   * @param message 
   * @param okText 
   * @returns 
   */
  openDialog(title, message, okText): Observable<any> {
    const dialogRef = this.dialog.open(DialogComponent, {
      minWidth: '30vw',
      maxWidth: '50vw',
      data: { title, message, okText }
    });

    return dialogRef.afterClosed();
  }

  /**
   * Open the Delte Dialog to let the User confirm the Deletion
   * @param title 
   * @param message 
   * @param okText 
   * @param cancelText 
   * @param object 
   * @param type 
   * @returns 
   */
  openDeleteDialog(title, message, okText, cancelText, object, type): Observable<any> {
    if (type == 'members') {
      const dialogRef = this.dialog.open(DeleteDialogComponent, {
        data: { title, message, okText, cancelText, object, type }
      });
      return dialogRef.afterClosed();
    } else if (type == 'channel') {
      const dialogRef = this.dialog.open(DeleteDialogComponent, {
        data: { title, message, okText, cancelText, object, type }
      });
      return dialogRef.afterClosed();
    } else if (type == 'openingHours') {
      const dialogRef = this.dialog.open(DeleteDialogComponent, {
        data: { title, message, okText, cancelText, object, type }
      });
      return dialogRef.afterClosed();
    } else if (type == 'boxes') {
      const dialogRef = this.dialog.open(DeleteDialogComponent, {
        data: { title, message, okText, cancelText, object, type }
      });
      return dialogRef.afterClosed();
    }
  }

  ope

  openAddCategoriesDialog(title, okText, cancelText, event: EventResource) {
    const dialogRef = this.dialog.open(AddCategoriesComponent, {
      minWidth: '30vw',
      maxWidth: '40vw',
      minHeight: '40vh',
      autoFocus: true,
      data: { title, okText, cancelText, event }
    });
    return dialogRef.afterClosed();
  }
  /**
   * Open the Error Dialog
   * @param title 
   * @param message 
   * @param okText 
   * @returns 
   */
  openErrorDialog(title, message, okText): Observable<any> {
    const dialogRef = this.dialog.open(ErrorDialogComponent, {
      minWidth: '10vw',
      maxWidth: '350px',
      autoFocus: true,
      data: { title, message, okText }
    });
    return dialogRef.afterClosed();
  }
  /**
   * Open the Modal to edit the Code for an Event
   * @param title 
   * @param message 
   * @param okText 
   * @param eventID 
   * @returns 
   */
  openVoucherCodesEdit(title, message, okText, eventID): Observable<any> {
    const dialogRef = this.dialog.open(EditVoucherCodesDialogComponent, {
      minWidth: '30vw',
      maxWidth: '50vw',
      data: { title, message, okText, eventID }
    });

    return dialogRef.afterClosed();
  }
  /**
   * Open the Dialog to view all used Vouchercodes
   * @param title 
   * @param message 
   * @param okText 
   * @param eventID 
   * @returns 
   */
  openUsedVoucherCodes(title, message, okText, eventID): Observable<any> {
    const dialogRef = this.dialog.open(ShowVoucherCodesComponent, {
      minWidth: '30vw',
      maxWidth: '50vw',
      data: { title, message, okText, eventID }
    });

    return dialogRef.afterClosed();
  }
  /**
   * Open a BottomSheet to change a state
   * @param event 
   * @returns 
   */
  openBottomSheet(event: EventResource): Observable<any> {
    const dialogRef = this._bottomSheet.open(ChangeEventStateComponent, {
      data: { event }
    });
    return dialogRef.afterDismissed();
  }
  /**
   * Open the BottomSheet to change the Stat of a BusinessEntity
   * @param businessEntityResource 
   * @returns 
   */
  openBottomSheetBusinessEntityResourceState(businessEntityResource: BusinessEntityResource): Observable<any> {
    const dialogRef = this._bottomSheet.open(ChangeBusinessEntityStateComponent, {
      data: { businessEntityResource }
    });
    return dialogRef.afterDismissed();
  }
  /**
   * Open the BottomSheet to change the State of a BusinessUser
   * @param businessUserResource 
   * @returns 
   */
  openBottomSheetBusinessUserResourceState(businessUserResource: BusinessUserResource): Observable<any> {
    const dialogRef = this._bottomSheet.open(ChangeBusinessUserStateComponent, {
      data: { businessUserResource }
    });
    return dialogRef.afterDismissed();
  }
  /**
   * Open the BottomSheet to change the Role of a BusinessUser
   * @param businessUserResource 
   * @returns 
   */
  openBottomSheetBusinessUserResourceRole(businessUserResource: BusinessUserResource): Observable<any> {
    const dialogRef = this._bottomSheet.open(ChangeBusinessUserRoleComponent, {
      data: { businessUserResource }
    });
    return dialogRef.afterDismissed();
  }

  /**
   * Open the Dialog to add a Member to a household
   * @param title 
   * @param message 
   * @param okText 
   * @param head 
   * @returns 
   */
  openAddHouseholdMemberDialog(title, message, okText, head: BusinessUserResource): Observable<any> {
    const dialogRef = this.dialog.open(AddHouseHoldmemberComponent, {
      minWidth: '30vw',
      maxWidth: '350px',
      autoFocus: true,
      data: { title, message, okText, head }
    });
    return dialogRef.afterClosed();
  }

  openResetPWUserDialog(title, message, okText, user: BusinessUserResource): Observable<any> {
    const dialogRef = this.dialog.open(ResetPWForUserComponent, {
      minWidth: '30vw',
      maxWidth: '350px',
      autoFocus: true,
      data: { title, message, okText, user }
    });
    return dialogRef.afterClosed();
  }
  /**
   * Open the Dialog to show the QR Code to a Event
   * @param title 
   * @param message 
   * @param okText 
   * @param event 
   * @returns 
   */
  openQrCodeDialog(title, message, okText, event: EventResource): Observable<any> {
    const dialogRef = this.dialog.open(ShowQrCodeComponent, {
      minWidth: '30vw',
      maxWidth: '350px',
      autoFocus: true,
      data: { title, message, okText, event }
    });
    return dialogRef.afterClosed();
  }
/**
 * Open the Dialog to show the QR Code to a Card
 * @param title 
 * @param okText 
 * @param card 
 * @returns 
 */
  openCardQrCodeDialog(title, okText, card: CardResource): Observable<any> {
    const dialogRef = this.dialog.open(ShowCardQrCodeComponent, {
      minWidth: '30vw',
      maxWidth: '350px',
      autoFocus: true,
      data: { title, okText, card }
    });
    return dialogRef.afterClosed();
  }
/**
 * Open the Dialog to Reopen an Account
 * @param title 
 * @param okText 
 * @param user 
 * @returns 
 */
  reopenAccount(title, okText, user: User): Observable<any> {
    const dialogRef = this.dialog.open(ReopenAccountComponent, {
      minWidth: '30vw',
      maxWidth: '350px',
      autoFocus: true,
      disableClose: true,
      data: { title, okText, user }
    });
    return dialogRef.afterClosed();
  }
/**
 * Open the Dialog to let the User confirm the contract Deletion
 * @param title 
 * @param message 
 * @param okText 
 * @param cancelText 
 * @returns 
 */
  openDeleteInterptorDialog(title, message, okText, cancelText): Observable<any> {
    const dialogRef = this.dialog.open(DeleteComponent, {
      minWidth: '30vw',
      maxWidth: '350px',
      autoFocus: true,
      data: { title, message, okText, cancelText }
    });
    return dialogRef.afterClosed();
  }
/**
 * Open the Dialog to confirm the Update of the Eventstate
 * @param title 
 * @param message 
 * @param okText 
 * @param cancelText 
 * @returns 
 */
  openAutoUpdateEventStatusComponent(title, message, okText, cancelText): Observable<any> {
    const dialogRef = this.dialog.open(AutoUpdateEventStatusComponent, {
      minWidth: '30vw',
      maxWidth: '350px',
      autoFocus: true,
      data: { title, message, okText, cancelText }
    });
    return dialogRef.afterClosed();

  }

  /**
 * Open the Dialog to confirm the Update of the Eventstate
 * @param title 
 * @param message 
 * @param okText 
 * @param cancelText 
 * @returns 
 */
  openAutoEventStatusInfoComponent(title, message, okText, cancelText): Observable<any> {
    const dialogRef = this.dialog.open(AutoUpdateEventInfoStatusComponent, {
      minWidth: '30vw',
      maxWidth: '350px',
      autoFocus: true,
      data: { title, message, okText, cancelText }
    });
    return dialogRef.afterClosed();

  }



  


  openLostPW(title, okText): Observable<any> {
    const dialogRef = this.dialog.open(LostPWComponent, {
      minWidth: '30vw',
      maxWidth: '50vw',
      data: { title,  okText }
    });

    return dialogRef.afterClosed();
  }


  openDelteUserDialog(title, message, okText,publicId): Observable<any> {
    const dialogRef = this.dialog.open(DelteUserComponent, {
      minWidth: '30vw',
      maxWidth: '50vw',
      data: { title, message, okText,publicId }
    });

    return dialogRef.afterClosed();
  }

  openCustomerSearchDialog(title, message, okText, targetUser): Observable<any> {
    const dialogRef = this.dialog.open(SearchCustomerComponent, {
      minWidth: '30vw',
      maxWidth: '50vw',
      data: { title, message, okText, targetUser }
    });

    return dialogRef.afterClosed();
  }

  openEditMailDialog(title, message, okText,channel:ContactChannelResource,userID:String){
    const dialogRef = this.dialog.open(EditMailContactComponent, {
      minWidth: '30vw',
      maxWidth: '50vw',
      data: { title, message, okText, channel ,userID}
    });
    return dialogRef.afterClosed();   
  }


  openDeactivateUserDialog(title, message, okText,cancelText,customer:User){
    const dialogRef = this.dialog.open(DeactivateUserComponent, {
      minWidth: '30vw',
      maxWidth: '50vw',
      data: { title, message, okText, cancelText,customer}
    });
    return dialogRef.afterClosed();   
  }

  
}