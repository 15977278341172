<h3 mat-dialog-title>{{ data.title }}</h3>
<div mat-dialog-content>
    <div class="row" [formGroup]="filterForm">
        <div class="col-sm-12">
            <mat-form-field class="full-width" appearance="fill">
                <mat-label>Suche</mat-label>
                <input type="text" matInput formControlName="searchTerm" placeholder="suchen.."
                    (keydown)="onKeydown($event)">
            </mat-form-field>
        </div>
    </div>

    <button mat-button mat-raised-button color="accent" (click)="filterCustomers()">
        Anwenden
    </button>

    @if (this.dataSource?.data?.length != 0) {
        <div class="row">
            <div class="col-sm-12">
                <div class="example-container">
                    <table mat-table [dataSource]="dataSource" matSort>
                        <ng-container matColumnDef="menu">
                            <th mat-header-cell *matHeaderCellDef>
                                Auswählen
                            </th>
                            <td mat-cell *matCellDef="let element;let i = index;">
                                <mat-checkbox [checked]="selected === i" (change)="selected = i"
                                    (click)="setActiveCustomer(element)">
                                </mat-checkbox>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="user">
                            <th mat-header-cell *matHeaderCellDef>
                                Kunde
                            </th>
                            <td mat-cell *matCellDef="let element" class="mat-column w70" style="padding-right: 25px;">
                                <span>
                                    {{element.person?.displayName}} ({{element?.customerNumber}})
                                </span>
    
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;let i = index">
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    }

</div>

<div mat-dialog-actions align="end">
    <button mat-button (click)="cancel()">Abbrechen</button>
    <button mat-button mat-raised-button color="primary" cdkFocusInitial (click)="moveUser()">{{ data.okText
        }}</button>
</div>