import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { BusinessUserResource } from 'src/app/interfaces/business-user-resource';
import { BusinessuserService } from 'src/app/services/businessuser.service';
import { OverlayService } from 'src/app/services/overlay.service';
/**
 * The Data to be passed into the Dialog
 */
export interface DialogData {
  /**
   * The Title of the Dialog
   */
  title: string;
  /**
   * The message in the Dialog
   */
  message: string;
  /**
   * The okText on the Button
   */
  okText: string;
  /**
   * The Text on the cancel Button
   */
  targetUser: string;
}

@Component({
  selector: 'app-search-customer',
  templateUrl: './search-customer.component.html',
  styleUrl: './search-customer.component.scss'
})
export class SearchCustomerComponent {
  filterForm: UntypedFormGroup;
  dataSource: MatTableDataSource<BusinessUserResource>;
  // 'number',
  displayedColumns = ['menu', 'user'];
  selectedUser:BusinessUserResource;
  selected = -1;
  /**
   * constructor
   * @param dialogRef 
   * @param data 
   */
  constructor(
    public dialogRef: MatDialogRef<SearchCustomerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: UntypedFormBuilder,
    private businessuserService: BusinessuserService,
    private overlayService:OverlayService,
    private router:Router
  ) {
    this.filterForm = this.fb.group({
      searchTerm: ['']
    })
  }

  ngOnInit(): void {
  }
  /**
  * Close the Dialog
  */
  cancel(): void {
    this.dialogRef.close();
  }
  onKeydown(event) {
    if (event.key === "Enter") {
      this.filterCustomers()
    }
  }

  filterCustomers() {
    this.businessuserService.getAllBusinessUsersWtihFilter(0, 10, this.filterForm.value.searchTerm, ['MAINCUSTOMER'], ['ACTIVE'], null,null,null,null,null,null).subscribe({
      next: res => {
        console.log(res)
        this.dataSource = new MatTableDataSource(res.content);
      },
      error: err => {
        console.log(err)
      }
    })
  }

  setActiveCustomer(cu) {
    this.selectedUser= cu;
  }

  moveUser(){
    this.businessuserService.switchToAnotherGroup(this.data.targetUser,this.selectedUser).subscribe({
      next: (res:BusinessUserResource) =>{
        console.log(res)
        this.overlayService.openSnackBar("Nutzer wurde umgezogen!");
        this.cancel();
      },
      error: error=>{
        console.log(error)
        this.overlayService.openSnackBar(error?.message);
      }
    })
  }
}
