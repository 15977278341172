<h3 mat-dialog-title>{{ data.title }}</h3>
<div mat-dialog-content [formGroup]="editMailForm">
    {{ data.message}}
    
    <div class="col-sm-12">
        <mat-checkbox formControlName="requestConfirmation">
            Kundenbestätigung der E-Mail anfordern
        </mat-checkbox>
    </div>
</div>

<div mat-dialog-actions align="end">
    <button mat-button (click)="cancel()">Abbrechen</button>
    <button mat-button mat-raised-button color="primary"  cdkFocusInitial (click)="editChannel()">{{ data.okText }}</button>
</div>