import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { Page404Component } from './components/shared/page404/page404.component';
import { AuthGuard } from './guards/auth.guard';
import { RoleAdminGuard } from './guards/role-admin.guard';
import { RolePartnerGuard } from './guards/role-partner.guard';
import { RoleCaseworkerGuard } from './guards/role.guard';


const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },

  {
    path: 'admin',
    canActivateChild: [AuthGuard, RoleAdminGuard],
    loadChildren: () => import('./components/inside/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'caseworker',
    canActivateChild: [AuthGuard, RoleCaseworkerGuard],
    loadChildren: () => import('./components/inside/caseworker.module').then(m => m.CaseworkerModule)
  },
  {
    path: 'partner',
    canActivateChild: [AuthGuard, RolePartnerGuard],
    loadChildren: () => import('./components/inside/parter.module').then(m => m.ParterModule)
  },
  {
    path: '',
    redirectTo : 'login',
    pathMatch : 'full'
  },
  { path: '404', component: Page404Component },
  { path: '**', redirectTo: '/404', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      //enableTracing: true,
      scrollPositionRestoration: 'top',
     // anchorScrolling: "enabled",
      
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
