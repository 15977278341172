import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EnvironmentService } from './environment.service';
import { BehaviorSubject, Observable, of, ReplaySubject, Subject, throwError } from 'rxjs';
import { BusinessUserResource } from '../interfaces/business-user-resource';
import { BusinessEntityResource } from '../interfaces/business-entity-resource';
import { map, retry, switchMap } from 'rxjs/operators';
import { ContactChannelResource } from '../interfaces/contactchannel';
import { PagedResources } from '../interfaces/paged-resources';
import { AuthenticationService } from './authentication.service';
import { supplyContract } from '../interfaces/supplyContract';
import { ReOpenAccountRequest } from '../interfaces/reopen-account-request';
import { UserProperty } from '../interfaces/user-property';
/**
 * The Filter  for the  BusinessUsers
 */
export interface BusinessUserFilter {
  /**
   * filter by the Roles
   */
  filterByRoles: Array<string>,
  /**
   * filter by the State of the user
   */
  filterByStates: Array<string>,
  /**
   * The searchTerm to search for
   */
  searchTerm: string,
}

/**
 * Servie used to CRUD Users
 */
@Injectable({
  providedIn: 'root'
})
export class BusinessuserService {
  /**
   * The count of all Users
   */
  businesUserCount$: Subject<number> = new ReplaySubject(1);
  /**
   * The count of all Mainuser (Householdhead)
   */
  MainCustomerCount$: Subject<number> = new ReplaySubject(1);
  /**
   * The count of all subusers (in Household groups)
   */
  SubCustomerCount$: Subject<number> = new ReplaySubject(1);
  /**
   * The count of pendig new Customers
   */
  pendingCustomers$: Subject<number> = new ReplaySubject(0);
  /**
   * Count of Customers waiting for Deactivation
   */
  waitingForDeactivation$: Subject<number> = new ReplaySubject(0);
  /**
   * Count of Customers wiht only a partial math in the  Import
   */
  partialMatch$: Subject<number> = new ReplaySubject(0);
  /**
   * The Count of Households with more tha 3 Users
   */
  householeBiggerThanThree$: Subject<number> = new ReplaySubject(0);
  /**
   * Count of Users that cant use the Service
   */
  nonEligibleUsers$: Subject<number> = new ReplaySubject(0);
  /**
   * Count of Users that want a new CustomerCard
   */
  newCards$: BehaviorSubject<number> = new BehaviorSubject(null);
  /**
   * Count of Users that dont have a Card yet
   */
  firstCards$: BehaviorSubject<number> = new BehaviorSubject(null);
  /**
   * constructor set the BehaviorSubjects and ReplaySubjects
   * @param http 
   * @param environment 
   * @param auth 
   */
  constructor(
    private http: HttpClient,
    private environment: EnvironmentService,
    private auth: AuthenticationService) {
    if (this.auth.userRole != 'admin') {
      this.getAllBusinessUserCount(0, 1).subscribe(value => {
        this.businesUserCount$.next(value.page.totalElements)
      })

      this.getAllMainCustomersCount(0, 1).subscribe(value => {
        this.MainCustomerCount$.next(value.page.totalElements)
      })

      this.getAllSubCustomersCount(0, 1).subscribe(value => {
        this.SubCustomerCount$.next(value.page.totalElements)
      })

      this.getAllBusinessUsersWtihFilter(0, 1, null, null, ["PENDING"], null, null, null, null, null).subscribe((val: PagedResources<BusinessUserResource>) => {
        this.pendingCustomers$.next(val.page.totalElements)
      })

      this.getAllBusinessUsersWtihFilter(0, 1, null, null, ["DISABLE_REQUEST"], null, null, null, null, null).subscribe((val: PagedResources<BusinessUserResource>) => {
        this.waitingForDeactivation$.next(val.page.totalElements)
      })

      this.getAllBusinessUsersWtihFilter(0, 1, null, ["MAINCUSTOMER"], ["PENDING", "ACTIVE"], null, null, "PARTIAL_MATCH", null, null).subscribe((val: PagedResources<BusinessUserResource>) => {
        this.partialMatch$.next(val.page.totalElements)
      })

      this.getNonEligibaleUsers().subscribe((val: PagedResources<BusinessUserResource>) => {
        this.nonEligibleUsers$.next(val.page.totalElements)
      })

      this.getAllBusinessUsersWtihFilter(0, 1, null, ["MAINCUSTOMER", "SUBCUSTOMER"], ["ACTIVE"], null, ["CLOSE_REQUEST"], null, null, null).subscribe((val: PagedResources<BusinessUserResource>) => {
        this.newCards$.next(val.page.totalElements)
      })

      this.getFirstCardCount().subscribe((val: PagedResources<BusinessUserResource>) => {
        this.firstCards$.next(val.page.totalElements)
      })

    } else {
      this.MainCustomerCount$.next(1)
    }
  }

  /**
   * Search for Users via Filter
   * @param page 
   * @param size 
   * @param searchTerm 
   * @param filterByRoles 
   * @param filterByStates 
   * @param type 
   * @returns 
   */

  search(page, size, searchTerm, filterByRoles, filterByStates, type): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size
      }
    })
    const body = {
      searchTerm: searchTerm,
      filterByStates: filterByStates,
      type: type,
      filterByRoles: filterByRoles
    }


    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/businessuser/search`, body, { params })
      )
    )
  }
  /**
   * Get all users paginated
   * @param page 
   * @param size 
   * @returns 
   */
  getAllBusinessUsers(page, size): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size
      }
    })

    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/businessuser`, { params }))
    )
  }
  /**
   * Get the Count of all Users
   * @param page 
   * @param size 
   * @returns 
   */
  getAllBusinessUserCount(page, size): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size
      }
    })
    const body = {
      filterByStates: ['ACTIVE'],
    }
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/businessuser/search`, body, { params })
      )
    )
  }

  /**
   * Get the count of all heads of Households
   * @param page 
   * @param size 
   * @returns 
   */
  getAllMainCustomersCount(page, size): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size
      }
    })
    const body = {
      filterByStates: ['ACTIVE'],
      filterByCustomerType: ['MAINCUSTOMER']
    }
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/customer/search`, body, { params })))
  }
  /**
   * Get the Count of all memebers of households
   * @param page 
   * @param size 
   * @returns 
   */
  getAllSubCustomersCount(page, size): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size
      }
    })
    const body = {
      filterByStates: ['ACTIVE'],
      filterByCustomerType: ['SUBCUSTOMER']
    }
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/customer/search`, body, { params })
      )
    )
  }
  /**
   * Search for Users via Filter
   * @param page 
   * @param size 
   * @param searchTerm 
   * @param filterByCustomerType 
   * @param filterByState 
   * @param filterBySupplyContractState 
   * @param filterByCardState 
   * @param erpState 
   * @param hasNoValidELV 
   * @param hasNoCard 
   * @param filterByRoles 
   * @returns 
   */
  getAllBusinessUsersWtihFilter(page, size, searchTerm, filterByCustomerType, filterByState, filterBySupplyContractState, filterByCardState, erpState, hasNoValidELV, hasNoCard, filterByRoles?): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size
      }
    })
    const body = {
      searchTerm: searchTerm,
      filterByCustomerType: filterByCustomerType,
      filterByState: filterByState,
      filterBySupplyContractState: filterBySupplyContractState,
      filterByCardState: filterByCardState,
      erpState: erpState,
      hasNoValidELV: hasNoValidELV,
      hasNoCard: hasNoCard,
      filterByRoles: filterByRoles
    }

    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/customer/search`, body, { params })
      )
    )
  }

  /**
   * get all Users with the state hasNoValidELV
   * @returns 
   */
  getNonEligibaleUsers() {
    const params = new HttpParams({
      fromObject: {
        page: 0,
        size: 1
      }
    })

    const body = {
      hasNoValidELV: true,
      filterByCustomerType: ["MAINCUSTOMER"],
      filterByState: ["ACTIVE"],
    }
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/customer/search`, body, { params })
      )
    )
  }


  getFirstCardCount() {
    const params = new HttpParams({
      fromObject: {
        page: 0,
        size: 1
      }
    })

    const body = {
      hasNoCard: true,
      filterByState: ["ACTIVE"],
    }
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/customer/search`, body, { params })
      )
    )
  }
  /**
   * Get one User by its ID
   * @param id 
   * @returns 
   */
  getBusinessUser(id) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/customer/${id}`)
      )
    )
  }
  /**
   * Get the user witch is currently logged in (From decoded Token)
   * @param id 
   * @returns 
   */
  getBusinessUserForMenu(id) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/businessuser/${id}`).pipe(retry(3))
      )
    )
  }
  /**
   * Create a new User
   * @param data 
   * @returns 
   */
  addBusinessUser(data) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/customer`, data)))
  }
  /**
   * Update a User by ID
   * @param id 
   * @param data 
   * @returns 
   */
  updateBusinessUser(id, data) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/businessuser/${id}`, data)
      )
    )
  }
  /**
   * Reset the PW of a User
   * @param id 
   * @param data 
   * @returns 
   */
  resetPassword(id, data) {
    const body = { accountPublicId: id, ...data };
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/account/${id}/password`, body)))
  }
  /**
   * Change the State of a user
   * @param member 
   * @param state 
   * @returns 
   */
  changeState(member: BusinessUserResource, state) {
    member.account.state = state;
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/businessuser`, member)
      )
    )
  }
  /**
   * Chane the Role of a User
   * @param member 
   * @param role 
   * @returns 
   */
  changeRole(member: BusinessUserResource, role) {
    member.account.role.name = role;
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/businessuser`, member)
      )
    )
  }
  /**
   * Get the Data from an Imort for the User
   * @param page 
   * @param size 
   * @param searchTerm 
   * @returns 
   */
  getERPDataForNewCustomer(page, size, searchTerm): Observable<any> {
    const body = {
      searchTerm: searchTerm
    }
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/customer/erp/search?page=${page}&size=${size}`, body)
      )
    )
  }
  /**
   * Acitvate an ERP User
   * @param confirm 
   * @param user 
   * @returns 
   */
  activateERPUser(confirm, user): Observable<any> {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/customer/caseworker?confirm=${confirm}`, user, {})
      )
    )
  }
  /**
   * Get a User by its ID
   * @param publicID 
   * @returns 
   */
  getBuissnessUser(publicID: string) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/customer/${publicID}`, {})
      )
    )
  }
  /**
   * Get the User for the Admin view
   * @param publicID 
   * @returns 
   */
  getBuissnessUserAdmin(publicID: string) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/businessuser/${publicID}`, {})
      )
    )
  }
  /**
   * Edit a user by its ID
   * @param customer 
   * @returns 
   */
  editBuissnessUser(customer) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.put(`${url}/businessuser/${customer.publicId}`, customer)
      )
    )
  }


  editCustomer(customer) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.patch(`${url}/customer/${customer.publicId}`, customer)
      )
    )
  }

  editCustomerPUT(customer) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.put(`${url}/customer/${customer.publicId}`, customer)
      )
    )
  }
  /**
   * Edit a user from the Admin view
   * @param customer 
   * @returns 
   */
  editBuissnessUserAdmin(customer) {
    return this.http.put(`${this.environment.getApiUrl()}/businessuser/${customer.publicId}`, customer);
  }

  /**
   * Get all Members to the Mainusers household
   * @param publicId 
   * @returns 
   */
  getGroupMemersByID(publicId: string) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/businessuser/${publicId}/group`, {})))
  }
  /**
   * Get all contactchannels for the User
   * @param user  
   * @returns 
   */
  getContactChannels(user: (BusinessEntityResource)) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/customer/${user.publicId}/contactchannel`, {})
      )
    )
  }
  /**
   * Get a contactchannel by its ID
   * @param publicId 
   * @returns 
   */
  getContactChannelsByID(publicId: string) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/businessuser/${publicId}/contactchannel`)
      )
    )
  }
  /**
   * Create a new Contactchannel for the User
   * @param businesUserID 
   * @param contactChannelResource 
   * @returns 
   */
  createContactChannel(businesUserID, contactChannelResource: ContactChannelResource) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/businessuser/${businesUserID}/contactchannel`, contactChannelResource)
      )
    )
  }
  /**
   * Update a Contactchannel for the User
   * @param businesUserID 
   * @param contactChannelResource 
   * @returns 
   */
  updateContactChannel(businesUserID, contactChannelResource: ContactChannelResource) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.put(`${url}/businessuser/${businesUserID}/contactchannel/${contactChannelResource.publicId}`, contactChannelResource)
      )
    )
  }

  updateMailChannel(businesUserID, contactChannelResource: ContactChannelResource,requestConfirmation:boolean) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.put(`${url}/businessuser/${businesUserID}/contactchannel/EMAIL?requestConfirmation=${requestConfirmation}`, contactChannelResource)
      )
    )
  }

  /**
   * Delete a Contactchannel for the User
   * @param businesUserID 
   * @param contactChannelResource 
   * @returns 
   */
  delteContactChannel(businesUserID, contactChannelResource: ContactChannelResource) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.delete(`${url}/businessuser/${businesUserID}/contactchannel/${contactChannelResource.publicId}`)
      )
    )
  }
  /**
   * Deactivate the User
   * @param customer 
   * @returns 
   */
  deactivateUser(customer) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/customer/${customer.publicId}/deactivate`, {})
      )
    )
  }
  /**
   * Deactivate the User and set State to DISABLED
   * @param customer 
   * @returns 
   */
  deactivateUserForever(customer) {
    customer.account.state = 'DISABLED';
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.patch(`${url}/customer/${customer.publicId}`, customer)))
  }
  /**
   * Activate the User but dont send a mail to the User
   * @param customer 
   * @returns 
   */
  activateUserWithoutNotification(customer: BusinessUserResource) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.patch(`${url}/customer/${customer.publicId}/activate?notify=false`, {})))
  }
  /**
   * Activate the User and send a mail to the User
   * @param customer 
   * @returns 
   */
  activateUserWithNotification(customer: BusinessUserResource) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.patch(`${url}/customer/${customer.publicId}/activate?notify=true`, {})))
  }
  /**
   * Get the Group to the Customer by the Customer Object
   * @param customer 
   * @returns 
   */
  getCustomerGroup(customer) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/businessuser/${customer.publicId}/group`, {})))
  }
  /**
   * Get the Group to the Customer by the ID
   * @param customerID 
   * @returns 
   */
  getCustomerGroupWithID(customerID) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/businessuser/${customerID}/group`, {})
      )
    )
  }
  /**
   * Add a Customer to the Group
   * @param customer 
   * @param member 
   * @returns 
   */
  addToCustomerGroup(customer, member) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/customer/${customer.publicId}/group/member`, member)))
  }
  /**
   * Get the Head of the Customergroup
   * @param customerID 
   * @returns 
   */
  getGroupHead(customerID) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/businessuser/${customerID}/group/head`, {})))
  }
  /**
   * Set the Password of the User
   * @param user 
   * @param pw 
   * @param confirm 
   * @returns 
   */
  setPassword(user: BusinessUserResource, pw, confirm) {
    let body = {
      accountPublicId: user.publicId,
      newPassword: pw,
      passwordRecitation: confirm
    }
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.put(`${url}/account/${user.publicId}/password`, body)
      )
    )
  }
  /**
   * Get the Stats for the Household (savings / used Events count)
   * @param userID 
   * @returns 
   */
  getStatsForHouseHold(userID) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/event/stats?customerPublicId=${userID}`, {})))
  }
  /**
   * Activate the Mail of a User
   * @param contractID 
   * @param value 
   * @returns 
   */
  activateMail(contractID, value) {
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    const body = value
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/businesscontract/${contractID}/is-partner-mail-recipient`, body, options)
      )
    )
  }
/**
 * Get the Acoountoptions to the user by ID
 * @param businesUserID 
 * @returns 
 */
  getAccountOptionsForAccount(businesUserID) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/account/${businesUserID}/option`)
      )
    )
  }
/**
 * Edit  the Acoountoptions to the user by ID
 * @param businesUserID 
 * @param accountOptions 
 * @returns 
 */
  editAccountOptionsForAccount(businesUserID, accountOptions) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.put(`${url}/account/${businesUserID}/option`, accountOptions)
      )
    )
  }
/**
 * Get the Note to the User (only visible for SB)
 * @param businesUserID 
 * @returns 
 */
  getAccountNote(businesUserID) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/account/${businesUserID}/note`, { responseType: 'text' })
      )
    )
  }
/**
 * Edit  the Note to the User (only visible for SB)
 * @param businesUserID 
 * @param text 
 * @returns 
 */
  editAccountNote(businesUserID, text) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/account/${businesUserID}/note`, text, { responseType: 'text' })
      )
    )
  }
/**
 * Get the UserProperties
 * @param businesUserID 
 * @returns 
 */
  getUserProperties(businesUserID) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/businessuser/${businesUserID}/property`)
      )
    )
  }
/**
 * Edit the UserProperties
 * @param businesUserID 
 * @param prop 
 * @returns 
 */
  editUserProperties(businesUserID, prop) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/businessuser/${businesUserID}/property`, prop)
      )
    )
  }
  /**
   * Get all Energycontracts to the User
   * @param businesUserID 
   * @returns 
   */
  getContractsForUser(businesUserID) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/customer/${businesUserID}/contract`)
      )
    )
  }
/**
 * Edit the Energycontract to the User
 * @param businesUserID 
 * @param contract 
 * @returns 
 */
  editContractForUser(businesUserID, contract: supplyContract) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.put(`${url}/customer/${businesUserID}/contract/${contract.supplyContractRef}`, contract)
      )
    )
  }
/**
 * Add a the Energycontract to the User
 * @param businesUserID 
 * @param contract 
 * @returns 
 */
  addContractToUser(businesUserID, contract: supplyContract) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/customer/${businesUserID}/contract`, contract)
      )
    )
  }
/**
 * Delte an Energycontract from the User
 * @param businesUserID 
 * @param contract 
 * @returns 
 */
  deleteContractFromUser(businesUserID, contract: supplyContract) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.delete(`${url}/customer/${businesUserID}/contract/${contract.supplyContractRef}`)
      )
    )
  }
/**
 * Search for an Energycontract
 * @param page 
 * @param size 
 * @param businesUserID 
 * @param filterByStates 
 * @param filterByTypes 
 * @returns 
 */
  searchForContract(page, size, businesUserID, filterByStates, filterByTypes) {
    const body = {
      filterByStates: filterByStates,
      filterByTypes: filterByTypes
    }
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/customer/${businesUserID}/contract/search?page=${page}&size=${size}`, body)
      )
    )
  }
/**
 * Get the Data by the ContractID and UserID
 * @param businesUserID 
 * @param contractID 
 * @returns 
 */
  getContractData(businesUserID, contractID) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/customer/${businesUserID}/contract`).pipe(
        map((contracts: Array<supplyContract>) => {
          return contracts.filter(contract => contract.supplyContractRef == contractID)[0]
        })
      )
      )
    )
  }
/**
 * Get the Data Export for the User
 * @param customer 
 * @returns 
 */
  getDSGVOExport(customer) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/customer/${customer.publicId}/zip`, { responseType: 'blob' })
      )
    )
  }
/**
 * Reopen an Account
 * @param reOpenAccountRequest 
 * @returns 
 */
  reOpenAccount(reOpenAccountRequest: ReOpenAccountRequest) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post<any>(`${url}/account/reopen`, reOpenAccountRequest))
    )
  }


  getErpUserWithFilter(page, size, searchTerm): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size
      }
    })
    const body = {
      searchTerm: searchTerm,
    }

    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/customer/erp/search`, body, { params })
      )
    )
  }


  deleteAccount(businesUserID) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.delete(`${url}/account/${businesUserID}`)
      )
    )
  }


  makeMainUser(businesUserID){
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.patch(`${url}/customer/${businesUserID}`,{"publicId":businesUserID,"businessRole":"MAINCUSTOMER"})
      )
    )
  }


  createNewOwnGroup(businesUserID){
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/customer/${businesUserID}/create-new-own-group`,{})
      )
    )
  }

  switchToAnotherGroup(usertoMoveID,targetUser){
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/businessuser/${usertoMoveID}/switch-to-another-group`,targetUser)
      )
    )
  }

  syncWithLemgoERP(customer:BusinessUserResource){
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/selfservices/swl/sync/${customer.customerNumber}`)
      )
    )

   
  }
}
