import { Component, Inject, OnInit } from '@angular/core';
import { EventService } from 'src/app/services/event.service';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { EventResource } from 'src/app/interfaces/event-resource';
import { OverlayService } from 'src/app/services/overlay.service';
/**
 * The Data to be passed into the Dialog
 */
interface DialogData {
  /**
   * The Event
   */
  event: EventResource;
}
/**
 * The Component to change the State of an Event
 */
@Component({
  selector: 'app-change-event-state',
  templateUrl: './change-event-state.component.html',
  styleUrls: ['./change-event-state.component.scss']
})
export class ChangeEventStateComponent implements OnInit {
  /**
   * THe State an Event can have
   */
  list = [
    { value: "CREATED", viewValue: "In Freigabe" },
    { value: "DRAFT", viewValue: "Entwurf" },
    { value: "PREVIEW", viewValue: "Vorschau" },
    { value: "ACTIVE", viewValue: "Aktiv" },
    { value: "HIDDEN", viewValue: "Versteckt" },
    { value: "CANCELLED", viewValue: "Abgelehnt" },
    { value: "EXPIRED", viewValue: "Abgelaufen" },
    { value: "ARCHIVED", viewValue: "Archiviert" },
    { value: "DELETED", viewValue: "Gelöscht" }
  ]
  /**
   * constructor
   * @param eventService 
   * @param data 
   * @param dialogRef 
   * @param overlayService 
   */
  constructor(
    private eventService: EventService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: DialogData,
    public dialogRef: MatBottomSheetRef<ChangeEventStateComponent>,
    private overlayService: OverlayService) {

  }
  /**
   * ngOnInit
   */
  ngOnInit(): void {

  }
  /**
   * Change the State of an Event
   * @param e 
   */
  changeState(e) {
    this.data.event.state = e
    this.eventService.changeState(this.data.event).subscribe((value: EventResource) => {
      if (value.publicId != null) {
        this.overlayService.openSnackBar('Status erfolgreich geändert!');
        this.dialogRef.dismiss();
      }
    })
  }
}
