import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication.service';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { environment } from 'src/environments/environment';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs';
/**
 * The Header with the Sidenav toggle
 */
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  /**
   * The Sidenav to be opend
   */
  @Input() sidenav: MatSidenav;
  /**
   * The Title of the APP set via ENV
   */
  title: string = environment.appName;
  /**
   * constructor
   * @param authenticationService 
   * @param router 
   */
  mode:string;
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private breakpointObserver:BreakpointObserver) {

  }
  /**
   * ngOnInit
   */
  ngOnInit() {
    this.breakpointObserver
      .observe('(min-width: 800px)')
      .pipe(map(({ matches }) => (matches ? 'side' : 'over'))).subscribe(val => {
        this.mode = val;
      });
  }

  /**
   * Logout the user and nav to back to login page
   */
  logout() {
    this.authenticationService.logout();
  }



}
