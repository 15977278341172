import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, combineLatest, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';
/**
 * The Service to get/set the current Mandant
 */
@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  /**
   * The Current Mandant Object
   */
  private currentEnv = {
    url: (environment.apiURL == 'null') ? '' : environment.apiURL,
    clientId: (environment.clientID == null) ? '' : environment.clientID,
    color: '#ff00ff'
  }
  /**
   * constructor
   * @param router 
   */
  constructor(private router: Router) {

  }
  /**
   * Set the current ClientConfig
   * @param config 
   */
  public setClientConfig(config) {
    if (config != null) {
      this.currentEnv = {
        url: config.backend,
        clientId: config.id,
        color: '#ff00ff'
      }
    } else {
      localStorage.removeItem('currentUser');
      localStorage.removeItem('config');
      this.router.navigate(['login']);
    }
  }
  /**
   * Get The API V1
   */
  get apiV1$(): Observable<string> {

    if(environment.apiURL != ''){
        return of(`${environment.apiURL}/api/v1/client/${environment.clientID}`);
    }else{
      return combineLatest([
        of(localStorage.getItem('config'))
      ]).pipe(
        map(([configuration]) => {
          let env = JSON.parse(configuration);
          return `${env.backend}/api/v1/client/${env.id}`;
        })
      );
    }

  }
  /**
   * Get The API V2
   */
  get apiV2$(): Observable<string> {
    return combineLatest([
      of(localStorage.getItem('config'))
    ]).pipe(
      map(([configuration]) => {
        let env = JSON.parse(configuration);
        return `${env.backend}/api/v2/client/${env.id}`;
      })
    );
  }
  /**
   * Get the API V1 URL
   * @param v 
   * @returns 
   */
  public getApiUrl(v = 'v1') {
    return `${this.currentEnv.url}/api/${v}/client/${environment.clientID}`;
  }
  /**
   * Get the URL for the Client Logo (V1)
   * @param v 
   * @returns 
   */
  public getApiUrlLogo(v = 'v1') {
    return `${this.currentEnv.url}/api/${v}/client/`;
  }
  /**
   * Get the URL for the Client Logo (Dynamic)
   * @param v 
   * @returns 
   */
  public getApiUrlLogoBaeder(v = 'v1') {
    return `${this.currentEnv.url}/${environment.clientID}/dynamic/`;
  }
  /**
   * @deprecated
   * @param v 
   * @returns 
   */
  public getApiUrlWithV(v) {
    return `${this.currentEnv.url}/api/${v}/client/${environment.clientID}`;
  }
  /**
   * Get the Plain URL with Version
   * @param v 
   * @returns 
   */
  public getApiPlain(v = 'v1') {
    return `${this.currentEnv.url}/api/${v}/client`;
  }
/**
 * Get the Current ClientID
 * @returns 
 */
  public getClientId() {
    return environment.clientID;
  }
/**
 * Get the current URL
 * @returns 
 */
  public getUrl() {
    return this.currentEnv.url;
  }
}
