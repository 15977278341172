import { Pipe, PipeTransform } from '@angular/core';
/**
 * Transform the State of the ServiceRoleFilter based on the Type
 */
@Pipe({
  name: 'translateFilterServiceRole'
})
export class TranslateFilterServiceRolePipe implements PipeTransform {
  /**
   * Transform the state based on the Type
   * @param value 
   * @returns 
   */
  transform(value: string[]): string[] {
    let returnArray = [];
    value.forEach((element, index) => {
      switch (element) {
        case 'ROLE_ANON':
          if (returnArray.length == 0) {
            returnArray.push("Anonymous");
          }
          break;
        case 'ROLE_PRE_CUSTOMER':
          if (returnArray.length == 0) {
            returnArray.push("Vorkunde");
          }
          break;
        case 'ROLE_CUSTOMER':
          if (returnArray.length == 0) {
            returnArray.push("Kunde/Mitarbeiter");
          }
          break;
        case 'ROLE_PARTNER':
          if (returnArray.length == 0) {
            returnArray.push("Aktionspartner");
          }
          break;
        case 'ROLE_CASEWORKER':
          if (returnArray.length == 0) {
            returnArray.push("Sachbearbeiter");
          }
          break;
        case 'ROLE_ADMIN':
          if (returnArray.length == 0) {
            returnArray.push("Administrator");
          }
        case 'ROLE_SERVICE':
          if (returnArray.length == 0) {
            returnArray.push("Webservice");
          }
      }
    });
    return returnArray

  }

}
