import { Pipe, PipeTransform } from '@angular/core';
/**
 * Transform the State of the ExportFilter based on the Type
 */
@Pipe({
  name: 'translateExportType'
})
export class TranslateExportTypePipe implements PipeTransform {
  /**
   * Transform the state based on the Type
   * @param value 
   * @returns 
   */
  transform(value: string): string {
    switch (value) {
      case "MASTER_DATA_REPORT":
        return "Stammdaten Report"
      case "MASTER_DATA_ZIP_REPORT":
        return "Stammdaten Report (ZIP)"
      case "MANUAL_ERP_REPORT":
        return "Manueller ERP Sync Report"
      case "CUSTOMERS_XML":
        return "Kunden XML"
      case "CARDS_CSV":
        return "Kunden Karten Urladung (axxteq)"
      default:
        return value;
    }
  }

}
