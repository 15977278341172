import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PermissionDisplayNamePipe } from './permission-display-name.pipe';
import { SortPipe } from './sort.pipe';
import { ServiceFilterPipe } from './service-filter.pipe';
import { TranslateFilterInterestPipe } from './translate-filter-interest.pipe';
import { TranslateExportTypePipe } from './translate-export-type.pipe';
import { TranslateFilterServicesPipe } from './translate-filter-services.pipe';
import { TranslateFilterContractPipe } from './translate-filter-contract.pipe';
import { TranslateFilterCardPipe } from './translate-filter-card.pipe';
import { PrioStatePipe } from './prio-state.pipe';
import { AnonymizePipe } from './anonymize.pipe';
import { TranslateFilterPushPipe } from './translate-filter-push.pipe';
import { SanitizeHtmlPipe } from './sanitizeHTML.pipe';

@NgModule({
  declarations: [
    PermissionDisplayNamePipe,
    SortPipe,
    ServiceFilterPipe,
    TranslateFilterInterestPipe,
    TranslateExportTypePipe,
    TranslateFilterServicesPipe,
    TranslateFilterContractPipe,
    TranslateFilterCardPipe,
    PrioStatePipe,
    AnonymizePipe,
    TranslateFilterPushPipe,
    SanitizeHtmlPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    PermissionDisplayNamePipe,
    SortPipe,
    ServiceFilterPipe,
    TranslateFilterInterestPipe,
    TranslateExportTypePipe,
    TranslateFilterServicesPipe,
    TranslateFilterContractPipe,
    TranslateFilterCardPipe,
    PrioStatePipe,
    TranslateFilterPushPipe,
    AnonymizePipe,
    SanitizeHtmlPipe
  ]
})
export class SharedPipesModule { }
